import { get, isEmpty, omit, some, values } from 'lodash'
import { AdaptedComponent, ComponentAdapter } from './componentFactory'
import { FileType, WixUploadButtonComponent } from './types'
import { baseComponentAdapter } from './baseAdapter'
import appContext from '../../../src/viewer-app-module/DataBindingAppContext'
import { AppError } from '../../../src/logger'
import getFieldValue from '../../../src/helpers/getFieldValue'
import { RecordStoreRecord } from '../../record-store/service'
import { extendComponentAdapter } from '../helpers/extendAdapter'

export type AdaptedUploadButton = AdaptedComponent & UploadButtonExtendedApi

interface UploadButtonExtendedApi extends Partial<AdaptedComponent> {
  fileType: FileType
  uploadFiles: () => Promise<any>
}

export const uploadButtonAdapter: ComponentAdapter<
  WixUploadButtonComponent,
  AdaptedUploadButton
> = component => {
  const { logger } = appContext
  const extendedApi: UploadButtonExtendedApi = {
    isValid(record: RecordStoreRecord) {
      const { connectionConfig, validity } = component
      if (validity.valid) {
        return true
      }

      const hasErrorOtherThanValueMissing = some(
        values(omit(validity, ['valid', 'valueMissing'])),
        value => value,
      )
      if (hasErrorOtherThanValueMissing) {
        return false
      }

      const fieldConnectedToValue =
        get(connectionConfig, 'properties.value.fieldName') || ''

      return !isEmpty(getFieldValue(record, fieldConnectedToValue))
    },

    set fileType(fileType: FileType) {
      component.fileType = fileType
    },

    get fileType() {
      return component.fileType
    },

    getValue() {
      return component.value
    },

    clear() {
      component.reset()
    },

    async uploadFiles() {
      // TODO BOLT: remove this fallback, when bolt is dead
      if (typeof component.uploadFiles !== 'function') {
        logger.log(
          new AppError('uploadFiles API method is not supported by Platform'),
        )
        return component.startUpload().then(uploadResult => [uploadResult])
      }
      return component.uploadFiles()
    },
  }

  return extendComponentAdapter(baseComponentAdapter(component), extendedApi)
}

'use strict'
import * as modes from '@wix/wix-data-client-common/src/dataset-configuration/readWriteModes'
import isCompConfiguredToReadData from '../helpers/isCompConfiguredToReadData'
import isForm from '../helpers/isForm'
import {
  getReadWriteMode,
  getCollectionName,
  selectCurrentRecord,
} from '../dataset-controller/rootReducer'
import appContext from '../viewer-app-module/DataBindingAppContext'
import { DataBindingBi } from '.'

const { READ_WRITE, READ } = modes
const reportDatasetActiveOnPage = (
  state,
  connections,
  datasetType,
  isScoped,
  datasetId,
) => {
  if (isScoped) {
    return
  }
  const collectionId = getCollectionName(state)
  if (!collectionId) {
    return
  }

  const datasetMode = getReadWriteMode(state)
  const someRecordLoaded = !!selectCurrentRecord(state)

  const {
    logger,
    platform: {
      settings: {
        env: { editor: isEditor },
      },
    },
  } = appContext

  if (
    someRecordLoaded &&
    [READ_WRITE, READ].includes(datasetMode) &&
    connections.find(({ role, config }) =>
      isCompConfiguredToReadData(role, config),
    )
  ) {
    logger.log(
      new DataBindingBi({
        id: isEditor ? 153 : 152,
        collectionId,
        datasetId,
        datasetType,
        datasetMode,
      }),
    )
  }

  if (isForm(getReadWriteMode(state), connections)) {
    logger.log(
      new DataBindingBi({
        id: isEditor ? 157 : 156,
        collectionId,
        datasetId,
        datasetType,
        datasetMode,
      }),
    )
  }
}

const reportUserDataFilteringBI = ({
  state,
  numberOfResults,
  filteredBy,
  componentType,
  fieldType,
}) => {
  const { logger } = appContext

  const datasetMode = getReadWriteMode(state)
  const collectionId = getCollectionName(state)
  if (!collectionId) {
    return
  }

  logger.log(
    new DataBindingBi({
      id: 366,
      collectionId,
      datasetMode,
      numberOfResults,
      filteredBy,
      componentType,
      fieldType,
    }),
  )
}

export { reportDatasetActiveOnPage, reportUserDataFilteringBI }

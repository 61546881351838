import componentTypes from '@wix/wix-data-client-common/src/componentTypes'
import { shouldUpdateComponentFromRecord } from '../components/adapters/helpers'
import { transformFromRecordToView } from '../components/transformData'
import { AppError, VerboseMessage } from '../logger'
import getFieldValue from './getFieldValue'

const { Checkbox: checkboxSdkType, Dropdown: dropdownSdkType } = componentTypes

// TODO: try to get rid of this func?
export const getInputComponentValueAccessorName = component => {
  switch (component.type) {
    case checkboxSdkType:
      return 'checked'
    default:
      return 'value'
  }
}

// TODO: try to get rid of this func?
export const getInputComponentDefaultValue = component => {
  switch (component.type) {
    case checkboxSdkType:
      return false
    case dropdownSdkType:
      return ''
    default:
      return null
  }
}

const isValueEmpty = value =>
  value === undefined ||
  value === '' ||
  (Array.isArray(value) && value.length === 0)

export const updateComponentProps = ({
  component,
  connectionConfig,
  currentRecord,
  previousRecord,
  getFieldType,
  formatter,
  modeIsLivePreview,
  logger,
  PresetVerboseMessage,
}) => {
  if (!currentRecord) {
    return
  }
  const { role, isInput } = component
  const { properties = {} } = connectionConfig
  const valueDescription = {}

  Object.entries(properties).forEach(([propPath, { fieldName, format }]) => {
    try {
      const fieldType = getFieldType(fieldName).getOrElse('')
      const value = transformFromRecordToView({
        value: getFieldValue(currentRecord, fieldName),
        role,
        componentIsInput: isInput,
        fieldType,
        propPath,
        format,
        utils: {
          formatter,
        },
      })

      valueDescription[propPath] = value

      if (
        shouldUpdateComponentFromRecord({
          currentRecord,
          previousRecord,
          fieldName,
        })
      ) {
        if (modeIsLivePreview && isValueEmpty(value)) {
          return
        }

        component.setValue(value, { propPath, fieldType })
      }
    } catch (e) {
      logger.log(new AppError(`Failed setting ${propPath}`, { cause: e }))
    }
  })

  logger.log(
    new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
      component,
      description: valueDescription,
    }),
  )
}

import { forEach, get, isEmpty, reduce } from 'lodash'
import { ComponentDatabindingApiFactory } from './types'
import { ComponentRole, GoogleMapConnectionConfig } from '../types'
import { MapMarker } from '../inverted-dependencies/components/types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import appContext from '../../src/viewer-app-module/DataBindingAppContext'
import { AppError, VerboseMessage } from '../../src/logger'
import { getCurrentItemIndex } from '../../src/helpers/paginationUtils'
import { AdaptedGoogleMapComponent } from '../inverted-dependencies/components/googleMapAdapter'
import getFieldValue from '../../src/helpers/getFieldValue'
import { transformFromRecordToView } from '../../src/components/transformData'
import { selectCurrentRecord } from '../../src/dataset-controller/rootReducer'
import { RecordStoreRecord } from '../../src/record-store/service'
import { isNonEmptyConfig } from '../components/adapters/helpers/connectionConfigUtils'

export const googleMapBindingApi: ComponentDatabindingApiFactory<
  AdaptedGoogleMapComponent,
  GoogleMapConnectionConfig
> = (component, connectionConfig, context) => {
  const { logger } = appContext
  const {
    actions,
    getState,
    getFieldType,
    wixFormatter,
    PresetVerboseMessage,
  } = context

  const logVerboseForBinding = () => {
    const { properties } = connectionConfig
    const bindingDescription: Record<string, string> = {}

    forEach(properties, ({ fieldName }, propName) => {
      bindingDescription[propName] = fieldName
    })

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
        component,
        description: bindingDescription,
      }),
    )
  }

  const createMarkerFromRecord = (
    record: RecordStoreRecord,
    properties: GoogleMapConnectionConfig['properties'] = {},
    role: ComponentRole,
  ): MapMarker =>
    reduce(
      properties,
      (marker, { fieldName, format }, propPath) => {
        const fieldValue = getFieldValue(record, fieldName)

        if (propPath === 'address') {
          return Object.assign(marker, {
            address: get(fieldValue, 'formatted'),
            location: get(fieldValue, 'location'),
          })
        } else if (propPath === 'link' && isEmpty(fieldValue)) {
          return marker
        }

        const convertedValue = transformFromRecordToView({
          value: getFieldValue(record, fieldName),
          role,
          fieldType: getFieldType(fieldName).getOrElse(''),
          propPath,
          format,
          utils: {
            formatter: wixFormatter,
          },
        })
        return Object.assign(marker, {
          [propPath]: convertedValue,
        })
      },
      {} as MapMarker,
    )

  const updateComponentFromRecords = async () => {
    const { role } = component
    const { properties } = connectionConfig
    try {
      const { items: records } = await actions.fetchCurrentItems(getState())
      component.markers = records.map(record =>
        createMarkerFromRecord(record, properties, role),
      )
    } catch (e) {
      logger.log(new AppError('Failed setting markers', { cause: e }))
    }
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),

    isValidConnection() {
      return isNonEmptyConfig(connectionConfig)
    },

    bind() {
      logVerboseForBinding()
    },

    async onRecordsLoaded() {
      updateComponentFromRecords()
    },

    async onCurrentViewChanged() {
      updateComponentFromRecords()
    },

    async onCurrentIndexChanged() {
      const currentIndex = getCurrentItemIndex({ state: getState() }) as number
      component.setCenter(get(component, ['markers', currentIndex, 'location']))
    },

    async onCurrentRecordModified() {
      const { properties } = connectionConfig
      const { role } = component
      const record = selectCurrentRecord(getState())
      const currentIndex = getCurrentItemIndex({ state: getState() }) as number
      component.markers[currentIndex] = createMarkerFromRecord(
        record,
        properties,
        role,
      )
      component.setCenter(get(component, ['markers', currentIndex, 'location']))
    },
  }
}

import {
  USER_INPUT_FILTER_CHECKBOX_GROUP_ROLE,
  USER_INPUT_FILTER_CHECKBOX_ROLE,
  USER_INPUT_FILTER_DROPDOWN_ROLE,
  USER_INPUT_FILTER_RADIOGROUP_ROLE,
  USER_INPUT_FILTER_RANGE_SLIDER_ROLE,
  USER_INPUT_FILTER_SELECTION_TAGS_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'

export const APP_NAME = 'dbsm-viewer-app'

export { DATA_BINDING as APP_DEF_ID } from '@wix/app-definition-ids'
export const DEPRECATED_METHODS_MAP = new Map([['new', 'add']])

export const USER_INPUT_FILTER_ROLES = [
  USER_INPUT_FILTER_DROPDOWN_ROLE,
  USER_INPUT_FILTER_CHECKBOX_ROLE,
  USER_INPUT_FILTER_RADIOGROUP_ROLE,
  USER_INPUT_FILTER_CHECKBOX_GROUP_ROLE,
  USER_INPUT_FILTER_SELECTION_TAGS_ROLE,
  USER_INPUT_FILTER_RANGE_SLIDER_ROLE,
]

export const LIST_TYPE_USER_INPUT_FILTER_ROLES = [
  USER_INPUT_FILTER_DROPDOWN_ROLE,
  USER_INPUT_FILTER_RADIOGROUP_ROLE,
  USER_INPUT_FILTER_CHECKBOX_GROUP_ROLE,
  USER_INPUT_FILTER_SELECTION_TAGS_ROLE,
]

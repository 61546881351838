'use strict'
import { isEqual } from 'lodash'
import getFieldValue from '../../../helpers/getFieldValue'

const shouldSetAllFields = previousRecord => !previousRecord

export const wasFieldUpdated = ({ previousRecord, currentRecord, fieldName }) =>
  !isEqual(
    getFieldValue(previousRecord, fieldName),
    getFieldValue(currentRecord, fieldName),
  )

export default ({ previousRecord, currentRecord, fieldName }) =>
  shouldSetAllFields(previousRecord) ||
  wasFieldUpdated({ previousRecord, currentRecord, fieldName })

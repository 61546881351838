import { forEach, isEmpty } from 'lodash'
import { ComponentDatabindingApiFactory } from './types'
import { ComponentRole, GalleryConnectionConfig } from '../types'
import { AdaptedGallery } from '../inverted-dependencies/components/galleryAdapter'
import { GalleryItem } from '../inverted-dependencies/components/types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import appContext from '../../src/viewer-app-module/DataBindingAppContext'
import { AppError, VerboseMessage } from '../../src/logger'
import { selectCurrentRecord } from '../../src/dataset-controller/rootReducer'
import getFieldValue from '../../src/helpers/getFieldValue'
import { transformFromRecordToView } from '../../src/components/transformData'
import { getCurrentItemIndex } from '../../src/helpers/paginationUtils'
import { RecordStoreRecord } from '../../src/record-store/service'
import { isNonEmptyConfig } from '../components/adapters/helpers/connectionConfigUtils'

export const galleryBindingApi: ComponentDatabindingApiFactory<
  AdaptedGallery,
  GalleryConnectionConfig
> = (component, connectionConfig, context) => {
  const { logger, errorReporting } = appContext
  const {
    actions,
    getState,
    getFieldType,
    modeIsLivePreview,
    wixFormatter,
    PresetVerboseMessage,
  } = context

  const copyRecordToImage = (
    image: GalleryItem,
    record: RecordStoreRecord,
    properties: GalleryConnectionConfig['properties'],
    role: ComponentRole,
  ) => {
    forEach(properties, ({ fieldName, format }, propPath) => {
      const value = transformFromRecordToView({
        value: getFieldValue(record, fieldName),
        role,
        fieldType: getFieldType(fieldName).getOrElse(''),
        propPath,
        format,
        utils: {
          formatter: wixFormatter,
        },
      })
      image[propPath as keyof GalleryItem] = value
    })
  }

  const setCurrentIndexOfGallery = () => {
    component.currentIndex = getCurrentItemIndex({
      state: getState(),
    }) as number
  }

  const refreshView = async () => {
    const { role } = component
    const { properties } = connectionConfig

    const { items: records } = await actions.fetchCurrentItems(getState())

    try {
      const items = records.map(record => {
        const image = {} as GalleryItem
        copyRecordToImage(image, record, properties, role)
        return image
      })
      const noImages = items.every(({ src }) => !src)

      if (modeIsLivePreview && noImages) {
        return
      }

      logger.log(
        new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
          component,
          description: items,
        }),
      )
      component.setValue(items)
    } catch (e: any) {
      // is the value bound to the src property bad?
      if (e.name !== 'URIError') {
        throw e
      }
    }

    setCurrentIndexOfGallery()
  }

  const logVerboseForBinding = () => {
    const { properties } = connectionConfig
    const bindingDescription: Record<string, string> = {}

    forEach(properties, ({ fieldName }, propName) => {
      bindingDescription[propName] = fieldName
    })

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
        component,
        description: bindingDescription,
      }),
    )
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),

    isValidConnection() {
      return isNonEmptyConfig(connectionConfig)
    },

    bind() {
      component.onCurrentItemChanged(
        errorReporting(() => {
          actions.setCurrentIndex(component.currentIndex as number)
        }, AppError.withMessage('Gallery adapter onItemReady failed')),
      )

      logVerboseForBinding()
    },

    async onCurrentRecordModified() {
      const { role } = component
      const { properties } = connectionConfig

      const record = selectCurrentRecord(getState())
      const currentIndex = getCurrentItemIndex({ state: getState() }) as number

      const imagesToBeChanged = component.getValue() || []
      const imageToBeChanged = imagesToBeChanged[currentIndex]

      if (imageToBeChanged) {
        copyRecordToImage(imageToBeChanged, record, properties, role)
      }

      component.setValue(imagesToBeChanged)
      setCurrentIndexOfGallery()
    },

    async onRecordsLoaded() {
      refreshView()
    },

    async onCurrentViewChanged() {
      refreshView()
    },

    async onCurrentIndexChanged() {
      setCurrentIndexOfGallery()
    },
  }
}
